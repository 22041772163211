import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Box, } from '@mui/material';
import {ChannelCard, Videos} from './index';
import { fetchFromAPI } from '../utils/fetchFromAPI';

function ChannelDetail() {
  const [channelDetail, setChannelDetail] = useState(null);
  const [videos, setVideos] = useState([]);
  const { id } = useParams(); // it gets the id from the channelCard

  useEffect(() => {
    fetchFromAPI(`channels?part=snippet&id=${id}`)
    .then((data) => setChannelDetail(data?.items[0])); // fetching the channel details of that specific channel

    fetchFromAPI(`search?channelId=${id}&part=snippet&order=date`)
    .then((data) => setVideos(data?.items)); // fetching the videos of that specific channel 
  }, [id]);
  return (
    <Box minHeight='95vh'>
      <Box>
        <div
          style={{
            background:'#000',
            zIndex:10,
            height:'100px',
          }}
        />
        <ChannelCard channelDetail={channelDetail} marginTop='-120px'/>
      </Box>
      <Box display='flex' p='2'>
        <Box sx={{mr: {sm: '100px'}}} />
        <Videos videos={videos}/>
      </Box>
    </Box>
  )
}

export default ChannelDetail;
