import {useEffect, useState} from 'react';
import { Link, useParams} from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Box,  Typography, Stack } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { Videos } from './index';
import { fetchFromAPI } from '../utils/fetchFromAPI';

function VideoDetail() {
  const [videoDetail, SetVideoDetail] = useState(null);
  const [videos, setVideos] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    //getting details of the video to be played
    fetchFromAPI(`videos?part=snippet,statistics&id=${id}`)
    .then((data) => SetVideoDetail(data.items[0]))

    //getting recommendations for the similar videos
    fetchFromAPI(`search?part=snippet&relatedToVideoId=${id}&type=video`)
    .then((data) => setVideos(data.items))
  }, [id]);

  if(!videoDetail?.snippet) return 'Loading...';

  const {snippet: {title, channelId, channelTitle}, statistics: {viewCount, likeCount}} = videoDetail;

  return (
    <Box minHeight='90vh'>
      <Stack direction={{xs:'column', md:'row'}}>
        <Box flex={1}>
          <Box
            sx={{
              width:'100%',
              position:'sticky',
              top:'60px'
            }}
          >
            <ReactPlayer url={`https://www.youtube.com/watch?v=${id}`}  className='react-player' controls/>
            <Typography color='#fff' variant='h6' p={1} fontWeight='bold'>
              {title}
            </Typography>
            <Stack direction='row' justifyContent='space-between' sx={{color:'#fff'}} py={0} px={2}>
              <Link to={`/channel/${channelId}`}>
                <Typography variant={{sm:'subtitle1', md:'h6'}} color='#fff' fontWeight='bold'>
                  {channelTitle}
                  <CheckCircle sx={{fontSize:12, color: 'gray', ml:'5px'}} />
                </Typography>
              </Link>
              <Stack direction='row' alignItems='center' gap='20px' marginBottom="10px">
                <Typography variant='body2' sx={{opacity:0.7 }} fontWeight='bold'>
                  {parseInt(viewCount).toLocaleString()} views
                </Typography>
                <Typography variant='body2' sx={{opacity:0.7 }} fontWeight='bold'>
                  {parseInt(likeCount).toLocaleString()} likes
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Box px={2} py={{xs:'5', md:'1'}} alignItems='center' justifyContent='center'>
          <Videos videos={videos} direction='column'/>
      </Box>
      </Stack>
    </Box>
  )
}

export default VideoDetail
